<template>
  <span>
    <page-header title="Liste des périodes" icon="fas fa-list"></page-header>
    <b-row class="row-header">
      <b-col>Nom</b-col>
      <b-col>Date</b-col>
      <b-col v-if="canChange" class="text-right">Couleur</b-col>
    </b-row>
    <b-row v-for="period in periods" :key="period.id" class="row-line" :style="getColor(period)">
      <b-col>{{ period.name }}</b-col>
      <b-col>
        <b-row v-for="timeframe in period.timeframes" :key="timeframe.id">
          {{ timeframe.dateFrom | dateToString('dddd LL') }} -
          {{ timeframe.dateTo | dateToString('dddd LL') }}
        </b-row>
      </b-col>
      <b-col v-if="canChange" class="text-right">
        <a href
         @click.prevent="showModal(period)"
         :style="getColor(period)"
         class="btn btn-secondary btn-sm"
        >
          <i class="fas fa-pen"></i> Modifier
        </a>
      </b-col>
    </b-row>
    <b-modal
      dialog-class="modal-lg"
      id="bv-modal-edit-color"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
      :ok-disabled="!isFormValid()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-pen"></i>
          <span v-if="selectedPeriod"> Modifier la couleur de {{ selectedPeriod.name }}</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">{{ errorText }}</div>
      <div v-else-if="getFormError()" class="error-text">{{ getFormError() }}</div>
      <b-row>
        <b-col>
          <b-form-group label-for="name" label="Nom">
            <b-form-input v-model="periodName"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <color-select
            :background="background"
            :color="color"
            @selected="getSelectedColor($event)"
          />
        </b-col>
      </b-row>
      <div v-if="selectedPeriod">
        <b-row v-for="timeframe in timeframes" :key="timeframe.id">
          <b-col>
            <b-form-group
              :label-for="'dateFrom' + timeframe.id"
              :label="dateFromLabel(timeframe)"
              description="date de début de la période"
            >
              <b-form-input :id="'dateFrom' + timeframe.id" type="date" v-model="timeframe.dateFrom"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label-for="'dateTo' + timeframe.id" label="au" description="date de fin de la période">
              <b-form-input :id="'dateTo' + timeframe.id" type="date" v-model="timeframe.dateTo"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<script>
import PageHeader from '@/components/Layout/PageHeader'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { makeSeancePeriod } from '@/types/youth'
import ColorSelect from '@/components/Controls/ColorSelect'
import { mapActions } from 'vuex'
import { makeSchoolYear } from '@/types/schools'
import { comparePeriods, compareDates } from '@/utils/sorting'

export default {
  name: 'period-list',
  mixins: [BackendMixin],
  components: {
    ColorSelect,
    PageHeader,
  },
  data() {
    return {
      periods: [],
      background: '',
      selectedBackgroundColor: '',
      selectedTextColor: '',
      color: '',
      periodName: '',
      selectedPeriod: null,
      errorText: '',
      schoolYears: [],
      timeframes: [],
    }
  },
  mounted() {
    this.loadPeriods()
    this.loadSchoolYears()
  },
  computed: {
    canChange() {
      return this.hasPerm('youth.change_seanceperiod')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal(period) {
      this.errorText = ''
      this.periodName = period.name
      this.background = period.backgroundColor
      this.color = period.textColor
      this.selectedPeriod = period
      this.timeframes = this.selectedPeriod.timeframes.map(elt => elt.clone())
      this.$bvModal.show('bv-modal-edit-color')
    },
    async loadPeriods() {
      let url = '/api/youth/seance-periods/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.periods = resp.data.map(elt => makeSeancePeriod(elt)).sort(
          (elt1, elt2) => comparePeriods(elt1, elt2)
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getSchoolYear(timeframe) {
      const index = this.schoolYears.map(elt => +elt.id).indexOf(+timeframe.schoolYearId)
      if (index >= 0) {
        return this.schoolYears[index]
      }
      return null
    },
    getColor(elt) {
      return { backgroundColor: elt.backgroundColor, color: elt.textColor, }
    },
    getSelectedColor(event) {
      this.selectedBackgroundColor = event.color.background
      this.selectedTextColor = event.color.text
    },
    isFormValid() {
      return this.getFormError() === ''
    },
    getFormError() {
      if (this.periodName === '') {
        return 'Nom de période à saisir'
      } else {
        for (const timeFrame of this.timeframes) {
          const schoolYear = this.getSchoolYear(timeFrame)
          if (schoolYear) {
            if (timeFrame.dateFrom && timeFrame.dateTo) {
              if (compareDates(timeFrame.dateFrom, timeFrame.dateTo) >= 0) {
                return schoolYear.name + ': la date de fin doit être postérieure à celle de début'
              }
              if (compareDates(schoolYear.startDate, timeFrame.dateFrom) > 0) {
                return schoolYear.name +
                  ': la date de début doit être postérieure à la date de début de la saison'
              }
              if (compareDates(timeFrame.dateTo, schoolYear.endDate) > 0) {
                return schoolYear.name +
                  ': la date de fin doit être antérieure à la date de fin de la saison'
              }
            } else {
              if (!timeFrame.dateFrom) {
                return schoolYear.name + ': date de début à saisir'
              } else {
                return schoolYear.name + ': date de fin à saisir'
              }
            }
          } else {
            return 'Aucune saison associée à la période'
          }
        }
      }
      return ''
    },
    dateFromLabel(timeframe) {
      const schoolYear = this.getSchoolYear(timeframe)
      if (schoolYear) {
        return schoolYear.name + ': du'
      }
      return 'Du'
    },
    async onSave() {
      if (this.selectedPeriod) {
        const data = {
          'name': this.periodName,
          'background_color': this.selectedBackgroundColor,
          'text_color': this.selectedTextColor,
          'timeframes': [],
        }
        for (const timeFrame of this.timeframes) {
          data.timeframes.push(
            {
              instance: timeFrame.id,
              date_from: timeFrame.dateFrom,
              date_to: timeFrame.dateTo,
            }
          )
        }
        const url = '/api/youth/seance-periods/' + this.selectedPeriod.id + '/'
        let backendApi = new BackendApi('patch', url)
        try {
          let resp = await backendApi.callApi(data)
          await this.addSuccess(resp.data.message)
          this.$bvModal.hide('bv-modal-edit-color')
          await this.loadPeriods()
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.edit-color {
  float: right;
}
</style>
